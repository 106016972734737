<template>
<section class="content-wrapper">
  <vue-snotify></vue-snotify>
    <div class="page-header">
      <h3 class="page-title">
        Produit
      </h3>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="javascript:void(0);">Catalogue</a></li>
          <li class="breadcrumb-item active" aria-current="page">Produit</li>
        </ol>
      </nav>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-lg-4">
                <div class="border-bottom text-center pb-4">
                  <!-- <img src="../../assets/images/faces/face12.jpg" alt="profile" class="img-lg rounded-circle mb-3"/> -->
                  <img src="../assets/images/import/actu-1.jpg" alt="sample" class="rounded mw-100"/>
                  <p>{{productDetails.description}}</p>
                  <div class="d-block d-sm-flex justify-content-between">
                    <!-- <b-button variant="primary">Présentation du produit</b-button> -->
                    <!-- <b-button variant="gradient-success">Partager</b-button> -->
                  </div>
                </div>
                <div class="border-bottom py-4">
                  <p>Produits similaires</p>
                  <div>
                    <!-- <label style="cursor:pointer" class="badge badge-outline-dark" v-for="(sameCategory, idSameCategory) in sameCategories" :key="idSameCategory" @click="showProduct(sameCategory)">
                        {{sameCategory.name}}
                    </label> -->
                    <label style="cursor:pointer" class="badge badge-outline-dark" v-for="(sameCategory, idSameCategory) in sameCategories" :key="idSameCategory" >
                        <router-link :to="{ name:'product', params: { ulid: sameCategory.ulid }}">{{sameCategory.name}}</router-link>
                    </label>
                  </div>
                </div>
                <div class="py-4">
                  <p class="clearfix">
                    <span class="float-left">
                      Stock
                    </span>
                    <span class="float-right text-muted">
                      {{productDetails.stock}}
                    </span>
                  </p>
                  <p class="clearfix">
                    <span class="float-left">
                      Volume
                    </span>
                    <span class="float-right text-muted">
                      {{productDetails.volume}}
                    </span>
                  </p>
                  <p class="clearfix">
                    <span class="float-left">
                      Poids
                    </span>
                    <span class="float-right text-muted">
                      {{productDetails.weight}}
                    </span>
                  </p>
                  <p class="clearfix">
                    <span class="float-left">
                      Lieu d'expédition
                    </span>
                    <span class="float-right text-muted">
                      {{productDetails.shippinglocation}}
                    </span>
                  </p>
                  <p class="clearfix">
                    <span class="float-left">
                      Date de publication
                    </span>
                    <span class="float-right text-muted">
                      {{productDetails.datepublished}}
                    </span>
                  </p>
                </div>
                <b-button v-if="$store.state.isAuthenticated" variant="gradient-primary btn-block" v-b-modal.comment>Laisser un avis</b-button>
                <b-modal id="comment" title="Laisser un commentaire" size="md" hide-footer>
                  <form action="">
                    <b-form-group>
                      <label for="rating">Note</label>
                      <star-rating class="mx-auto" v-bind:increment="0.5" v-bind:rating="2.5" v-bind:max-rating="5" v-bind:star-size="25" v-bind:show-rating="false" v-model="rating"></star-rating>
                    </b-form-group>
                    <b-form-group>
                      <label for="comment">Commentaires</label>
                      <b-textarea type="text" placeholder="Votre commentaire" v-model="comment" required></b-textarea>
                    </b-form-group>
                    <div class="d-flex">
                    <b-button class="text-right" @click="share()">Publier</b-button>
                    </div>
                  </form>
                </b-modal>
              </div>
              <div class="col-lg-8">
                <div class="d-lg-flex justify-content-between">
                  <div>
                    <h3 class="mt-3 mt-lg-0">{{productDetails.name}}</h3>
                    <div class="d-flex align-items-center">
                      <h3 class="mb-0 mr-2 text-muted mt-3 mt-lg-0">{{productDetails.price}} €</h3>
                      <star-rating class="ml-3" 
                      v-bind:increment="0.5" 
                      v-bind:rating="averageRating" 
                      v-bind:max-rating="5"
                      v-bind:star-size="20" 
                      v-bind:show-rating="false"
                      v-bind:read-only="true" 
                      :active-color="'#b66dff'"
                        >
                      </star-rating>
                    </div>
                  </div>
                 

                  <div class="mt-3 mt-lg-0">
                    <b-button variant="outline-secondary btn-icon">
                      <i class="mdi mdi-comment-processing"></i>
                    </b-button>
                    <b-button variant="gradient-primary" @click="addProducts"><i class="mdi mdi-cart"></i></b-button>
                  </div>
                </div>
                <div class="mt-4 py-2 border-top border-bottom">
                  <ul class="nav profile-navbar">
                    <li class="nav-item">
                      <a class="nav-link active" href="javascript:void(0);">
                        <i class="mdi mdi-comment"></i>
                        Notes et commentaires
                      </a>
                    </li>
                    
                  </ul>
                </div>

                <!-- si non connecté -->
                <div v-if="!$store.state.isAuthenticated" class="mt-4">
                  <h6>Vous devez vous
                    <router-link to="/authentification">
                    connecter
                    </router-link>
                    pour voir les commentaires</h6>
                </div>
                <!-- si connecté -->
                <div v-else class="profile-feed mh-600 overflow-auto">
                  <div v-for="(rating, i) in userratings" :key='i' class="d-flex align-items-start profile-feed-item">
                    <img src="../assets/images/faces/face13.jpg" alt="profile" class="img-sm rounded-circle"/>
                    <div class="ml-4">
                      <h6>
                        {{rating.user}}
                        <small class="ml-4 text-muted"><i class="mdi mdi-clock mr-1"></i>{{rating.datepublished}}</small>
                      </h6>
                      <p>
                        {{rating.comment}}
                      </p>
                      <p class="small text-muted mt-2 mb-0">
                        <span>
                          <i class="mdi mdi-star mr-1"></i>{{rating.rating}}
                        </span>
                        <span class="ml-2">
                          <i class="mdi mdi-comment mr-1"></i>11
                        </span>
                        <span class="ml-2">
                          <i class="mdi mdi-reply"></i>
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</section>
</template>

<script>
const axios = require('axios').default
import {mapState} from 'vuex'
import StarRating from 'vue-star-rating'
export default {
    name: 'product',
    components: {
        StarRating
    },
    data () {
        return {
          link: 'essai',
          userratings:[],
          StarRating:'',
          catalogueDetails:'',
          productDetails:'',
          // products: [],
          averageRating:0,
          ulid:'',
          comment:'',
          rating:3,
          datePublished:'',
          dateModified:'',
          sameCategories:[]
        }
    },
    computed: {
      ...mapState (['uliduser']),
      ulidUser() {
        return this.$store.state.ulidUser
      }
    },
    beforeRouteUpdate(to, from, next) {
      console.log('page d\'avant',this.$route.path);
      next(this.getProducts())
      location.reload()
    },
    methods: {
        addProducts() {
        this.$store.dispatch('ADD_PRODUCTS',this.productDetails)
        this.$snotify.success('Produit ajouté au panier', {
          timeout: 2000,
          showProgressBar: false,
          closeOnClick: false,
          pauseOnHover: true,
        })
        },
        share() {
          axios.post(`myratings`,
          {
            ulidproduct: this.ulid,
            // uliduser: this.ulidUser,
            rating: this.rating,
            comment: this.comment,
            datepublished: this.datePublished,
            datemodified: this.dateModified
          },
          {
            withCredentials:true,
            headers: {
              'X-AUTH-TOKEN': localStorage.getItem('token')
            }
          }
          )
          .then(resRating=> {
            console.log(resRating)
            window.location.reload()
          })
          .catch(errRating=> {
            console.log({errRating})
            console.log('réponse de l\'erreur',errRating.response)
            errRating.response.data=="Can't rate a product that has not been bought !"
            ?this.$swal({
              type: 'error',
              title: 'Oops...',
              text: 'Vous ne pouvez pas commenter un produit que vous n\'avez pas encore acheté!',
              // footer: '<a href @click="addProducts">Why do I have this issue?</a>'
            })
            :''
          })
        },
        async getProducts() {
          //get products
          await axios.get(`products/${this.ulid}`)
          .then(resProduct => {
            this.productDetails=resProduct.data.result
            console.log('détails du produit1', this.productDetails)
            // console.log('catégories du produit', this.productDetails['ulidcategory'][0]);
          })
          .catch(errProduct => console.log('errProduct', errProduct))

          axios.get(`products/categories/${this.productDetails['ulidcategory'][0]}`)
          .then(resCategory => {
            let sameCategoryTable= resCategory.data.result
            this.sameCategories= sameCategoryTable.slice(Math.max(sameCategoryTable.length - 5 , 0))
            // this.sameCategories=resCategory.data.result
            console.log({resCategory})
            })
          .catch(errCategory=> console.log({errCategory}))
        },
        showProduct(product) {
          this.$router.push({
            name:"product",
            params: {
              ulid: product.ulid
            }
          })
          location.reload()
          
        }
    },
    mounted () {
        this.ulid = this.$route.params.ulid
        // console.log('ulid récupéré', this.ulid);
        
        //get userrating
        axios.get(`userratings/products/${this.ulid}`)
        .then(async res=> {
          // console.log('userratings', res)
          let userratings=res.data.result

          for(let x of userratings) {
            await axios.get(`users/${x.uliduser}`, {
              headers: {
                'X-AUTH-TOKEN': localStorage.getItem('token')
              }
            })
            .then(resX=> {  
              // console.log('userList',resX)
              x.user= resX.data.result.pseudo
            })
            .catch(errX=> console.log(errX))
          }

          userratings= userratings.slice().sort(( a, b) => new Date(b.datepublished) - new Date(a.datepublished))

      this.userratings= userratings
      // console.log('user userratings',this.userratings)

      // average rating
      let totalRatings=0
      for(const rate of this.userratings){
       totalRatings= totalRatings + rate.rating
      //  console.log('rate',rate)
       }
      this.averageRating= totalRatings/this.userratings.length
      // console.log('length',this.userratings.length)
      // console.log('totalrating',this.totalRatings)
      // console.log('total',this.averageRating)
      })
    .catch(err=> console.log(err))

    this.getProducts()

    this.datePublished = new Date().toISOString().replace(/T/, ' ').replace(/\..+/, '')
    this.dateModified = new Date().toISOString().replace(/T/, ' ').replace(/\..+/, '')

    }

}
</script>

<style scoped>
label a, a:hover {
  color: black;
  text-decoration: none
}
</style>

